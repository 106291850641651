import React from 'react';
import Anchor from './anchor';
import Abstract from './abstract';
import { Outline } from './outline';
import Example from './example';
import { Divider } from 'antd';
import GitHubButton from 'react-github-btn'
const BodyPage = () => {
	const vllmGithubStar = (
		<span style={{ verticalAlign: 'middle' }}>
		  <iframe src="https://ghbtns.com/github-btn.html?user=vllm-project&repo=vllm&type=star&count=true" frameborder="0" width="150" height="20" title="GitHub"></iframe>
		</span>
	  );
	  const lamacppGitHubStar = (
		<span style={{ verticalAlign: 'middle' }}>
		  <iframe src="https://ghbtns.com/github-btn.html?user=ggerganov&repo=llama.cpp&type=star&count=true" frameborder="0" width="150" height="20" title="GitHub"></iframe>
		</span>
	  );
	return (
		<div className='body'>
			{/* <Anchor /> */}
			<div>
				{/* <div className='outline__image'> */}
				{/* <img src='images/mech_rep_figure.svg' alt='outline' /> */}
				{/* </div> */}
				<div className='section'>
					<h2 className='section__title description'><Divider orientation="left">Latest News</Divider></h2>
					<ul className='news-list description_news'>
						<li><img src="images/repe_logo.png" className="repeicon"/>[6/2024]: Improving Alignment and Robustness with <a href="https://circuibreaker-website.vercel.app/" target="_blank" style={{backgroundColor: '#53B9FF', color: 'white', padding: '2px 4px', borderRadius: '3px'}}>Circuit Breakers</a> (NeurIPS 2024)</li>
						<li><img src="images/repe_logo.png" className="repeicon"/>[4/2024]: Control Vectors is in <a href="https://github.com/vllm-project/vllm/issues/3861" target="_blank">vLLM Roadmap Q2 2024</a> {vllmGithubStar} </li>
						<li><img src="images/repe_logo.png" className="repeicon"/>[3/2024]: RMU, an unlearning method inspired by RepE, is featured on <a href="https://time.com/6878893/ai-artificial-intelligence-dangerous-knowledge" target="_blank">TIME</a> along with <a href="https://www.wmdp.ai" target="_blank">The WMDP Benchmark</a> </li>
						<li><img src="images/repe_logo.png" className="repeicon"/>[3/2024]: RepControl is merged to llama.cpp: <a href="https://github.com/ggerganov/llama.cpp/pull/5970" target="_blank"> PR #5970: Add support for control vectors</a> {lamacppGitHubStar} </li>
						<li><img src="images/repe_logo.png" className="repeicon"/>[1/2024] A community blog makes to the first page on Hacker News:  <a href="https://vgel.me/posts/representation-engineering" target="_blank">Representation Engineering Mistral-7B an Acid Trip</a></li>
						<li><img src="images/repe_logo.png" className="repeicon"/>[10/2023] RepE is on Fox News: <a href="https://foxnews.com/us/researchers-shed-light-how-read-control-ai-systems-minds" target="_blank">Researchers shed light on how to read, control AI systems' minds</a></li>
					</ul>
				</div>
				<Abstract />
				<Outline />
				<Example />
			</div>
		</div>
	);
};

export default BodyPage;
